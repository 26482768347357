import './style.scss'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { BlogListProps } from './types'
import { useNavigate } from 'react-router-dom'

function BlogList(props: BlogListProps) {
  const { blogs } = props;
  const navigate = useNavigate();
  const handleClick = (url: string) => {
    navigate(url);
  }

  return (
    <>
      {blogs.map(item => (
        <div key={item.url} className="BlogEntry" onClick={() => handleClick(item.url)}>
          <Row>
            <Col md={{span: 10, offset: 2}} sm={12} className="BlogTitle">
              <h5>{item.title}</h5>
            </Col>
            <Col md={2} sm={12} className="BlogDate">
              <span>{item.date}</span>
            </Col>
            <Col md={10} sm={12} className="BlogPreview">
              <span>{item.preview}</span>
            </Col>
          </Row>
        </div>
      ))}
    </>
  )
}

export default BlogList
