import full_kit from './img/image001.png'
import mv from './img/SA_Ambassador_MV_4K.png'
import v4n from './img/SA_Ambassador_v4n.png'
import v4n2 from './img/SA_Ambassador_v4n_2.png'

const DxxAmbassador = [
  {
    type: 'header',
    value: 'SA Ambassador $150 (Cancelled)'
  },
  {
    type: 'text',
    value: 'Sculpted SA set using stock colors GTL, NN, VBV and RCJ. Deep dish F and J.'
  },
  {
    type: 'image',
    value: full_kit
  },
  {
    type: 'image',
    value: mv,
    lg: 4
  },
  {
    type: 'image',
    value: v4n,
    lg: 4
  },
  {
    type: 'image',
    value: v4n2,
    lg: 4
  }
]

export default DxxAmbassador
