import dsa_pwork_img from '../DynamicPage/dsaPaperwork/img/dsa_paperwork.png'
import dss_dnn_img from '../DynamicPage/dssNeonNights/img/neon_nights_vanagon.png'
import gt_img from '../DynamicPage/garbageTruck/img/gt.png'
import hull_img from '../DynamicPage/hullR1/img/hull.jpg'
import v3n_img from '../DynamicPage/vanagonR3/img/hullagon.png'
import dcs_classic_img from '../DynamicPage/dcsClassic/img/dcs_classic.png'
import coriander_brash_img from '../DynamicPage/coriander/img/coriander_brash.jpg'
import black_ketch_1_1_front_img from '../DynamicPage/nauticalPcbs/img/black_ketch_1.1_front.jpg'
import embrace_img from '../DynamicPage/embrace/img/embrace-assembled.jpg'
import ambassador_thumb from '../DynamicPage/dxxAmbassador/img/SA_Ambassador_MV_4K_thumb.png'

const data = [
  {
    name: 'DSA Paperwork',
    img: dsa_pwork_img,
    description: 'Paperwork colorway in the popular DSA profile by Signature Plastics.',
    url: '/page/dsa-paperwork'
  },
  {
    name: 'Nautical PCBs',
    img: black_ketch_1_1_front_img,
    description: 'MiniVan and Roadkit compatible pcbs, Ketch and Skiff respectively.',
    url: '/page/nautical-pcbs'
  },
  {
    name: 'DSS Neon Nights',
    img: dss_dnn_img,
    description: 'Neon Nights colorway in the emerging DSS profile by Signature Plastics',
    url: '/page/dss-neon-nights'
  },
  {
    name: 'Garbage Truck',
    img: gt_img,
    description: 'Mini 1800 style keyboard with a big ass knob.',
    url: '/page/garbage-truck'
  },
  {
    name: 'Hull R1 Memorial Edition',
    img: hull_img,
    description: 'Low profile MiniVan compatible keyboard case. Proceeds from this run were donated.',
    url: '/page/hull-r1'
  },
  {
    name: 'V4N4G0N R3',
    img: v3n_img,
    description: 'Low profile version of the popular V4N4G0N gaming keyboard.',
    url: '/page/vanagon-r3'
  },
  {
    name: 'DCS Classic',
    img: dcs_classic_img,
    description: 'Beige color in the DCS profile by Signature Plastics',
    url: '/page/dcs-classic'
  },
  {
    name: 'Coriander',
    img: coriander_brash_img,
    description: 'Corian case for minivan compatible pcbs',
    url: '/page/coriander'
  },
  {
    name: 'Hull R2 Preorder $85',
    img: hull_img,
    description: 'Low pro bottom mount MiniVan compatible case',
    url: '/page/hull-r2'
  },
  {
    name: 'Embrace Keyboard Kit $495',
    img: embrace_img,
    description: 'Leaf spring acrylic and steel gaming board',
    url: '/page/embrace'
  },
  {
    name: 'SA Ambassador $150',
    img: ambassador_thumb,
    description: 'New keyset to be done in SA Profile',
    url: '/page/sa-ambassador'
  }
];

export default data;
