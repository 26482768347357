import charcoal_serika from './img/charcoal_serika.jpeg'
import charcoal_bottom from './img/charcoal_bottom.jpeg'
import charcoal_serika_side from './img/charcoal_serika_side.jpeg'
import sand_pwork from './img/sand_pwork.jpeg'
import sand_pwork_side from './img/sand_pwork_side.jpeg'

const VanagonR4Page = [
  {
    type: 'header',
    value: 'V4N4G0N R4 Hall Effect MX Hybrid $375'
  },
  {
    type: 'sub-header',
    value: 'Coming January 1, 2025'
  },
  // {
  //   type: 'updates',
  //   updates: [
  //     // {date: '3-25-2022', content: 'I have completed bundling all kits. I have shipped about 2/3 of the orders and continue to push more out each day. Extra parts will get listed here after GB shipping concludes'},
  //     // {date: '2-16-2022', content: 'Anodizer claims that half of the cases should be shipped by this coming Friday'},
  //     // {date: '10-28-2021', content: 'I received word that the cases should be finished up within the next couple weeks. If I get tracking or anything more specific I will post another update here.'},
  //     // {date: '10-12-2021', content: 'All parts for the kits are ready except for the cases. The cases are with the anodizer who is currently short staffed and backed up. Once the cases are anodized and shipped to me, I will be packing and shipping the kits asap. If you have any questions about your order or need to update your shipping addres, please send an email to evan@thevankeyboards.com'}
  //   ]
  // },
  {
    type: 'text',
    value: 'V4N4G0N R4 features the classic case design from R1/R2 with many qaulity of life improvements. The plate is now tadpole mount. There are alignment pins on the case making assembly a breeze. The case screws have been reduced to just 4. An S1 daughterboard connects the pcb to the computer. The new PCB supports both MX and Hall Effect switches and ships with Vial firmware.'
  },
//   {
//     type: 'html',
//     value: `<form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
// <input type="hidden" name="cmd" value="_s-xclick">
// <input type="hidden" name="hosted_button_id" value="QWL2RQQWU3MLC">
// <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">
// <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1">
// </form>`
//   },
  {
    type: 'image',
    value: charcoal_serika
  },
  {
    type: 'text',
    value: 'These kits are expected to take about 5 months to produce. Each kit contains the following items.'
  },
  {
    type: 'list',
    values: [
      'Charcoal or Sand anodized aluminum case',
      '(4) Silver M3x10 screws',
      '(4) Rubber Feet',
      '(6) Tadpoles',
      'Full Space Layout FR4 Plate',
      'V4N4G0N Hybrid Hotswap PCB',
      'S1 Daughterboard',
    ]
  },
  {
    type: 'image',
    value: charcoal_serika_side,
    lg: 3
  },
  {
    type: 'image',
    value: charcoal_bottom,
    lg: 6
  },
  {
    type: 'image',
    value: sand_pwork_side,
    lg: 3
  },
  {
    type: 'image',
    value: sand_pwork
  },
]

export default VanagonR4Page;

