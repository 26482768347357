import charcoal_serika from '../DynamicPage/vanagonR4/img/charcoal_serika.jpeg'

const data = [
  {
    name: 'V4N4G0N R4 Hybrid $375',
    img: charcoal_serika,
    description: 'The much anticipated V4N4G0N returns in two new colors',
    url: '/page/v4n4g0n-r4'
  }
];

export default data;
