import './App.css';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import { useState } from 'react'
import InStock from './Pages/InStock';
import ClosedGroupBuys from './Pages/ClosedGroupBuys';
import DynamicPage from './Pages/DynamicPage';
import Home from './Pages/Home'
import OpenGroupBuys from './Pages/OpenGroupBuys'
import Blog from './Pages/Blog'
import ComingSoon from './Pages/ComingSoon'

function App() {
  const [expanded, setExpanded] = useState(false)
  const handleClose = () => setTimeout(() => setExpanded(false), 150);

  return (
    <Router>
      <Navbar bg="brand" variant="dark" expand="lg" expanded={expanded}>
        <Container>
          <Navbar.Brand as={Link} to="/" onClick={handleClose}>Trashman.club</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/" onClick={handleClose}>Home</Nav.Link>
              <Nav.Link href="https://qmk.trashman.club" onClick={handleClose}>Configurator</Nav.Link>
              <Nav.Link href="https://trashman.wiki" onClick={handleClose}>Wiki</Nav.Link>
              <Nav.Link as={Link} to="/page/gallery" onClick={handleClose}>Gallery</Nav.Link>
              <Nav.Link as={Link} to="/blog" onClick={handleClose}>Blog</Nav.Link>
              <NavDropdown title="Throw your money away" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/in-stock" onClick={handleClose}>In Stock</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/open-group-buys" onClick={handleClose}>Open Group Buys</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/closed-group-buys" onClick={handleClose}>Closed Group Buys</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/coming-soon" onClick={handleClose}>Coming Soon</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/in-stock" element={<InStock />} />
          <Route path="/closed-group-buys" element={<ClosedGroupBuys />} />
          <Route path="/open-group-buys" element={<OpenGroupBuys />} />
          <Route path="/coming-soon" element={<ComingSoon />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/page/:pageId" element={<DynamicPage />} />
        </Routes>
      </Container>
    </Router>
  );
}

export default App;
