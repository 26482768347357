import stab_img from '../DynamicPage/425stab/img/425stab.jpg'

const data = [
  {
    name: '425 Stab 2 Pack',
    img: stab_img,
    description: 'Stabilizer for 425 spacebars',
    url: '/page/425-stab'
  },
];

export default data;
