import carbon from './img/carbon.jpg';
import sand from './img/IMG_4644.jpg';

const VanagonAgainPage = [
  {
    type: 'header',
    value: 'V4N4G0N... Again!'
  },
  {
    type: 'sub-header',
    value: 'A Brief History'
  },
  {
    type: 'text',
    value: 'The V4N4G0N is my favorite keyboard design. I originally designed it a few months after closing my online keyboard shop. I was recovering from major burnout and rediscovering my love for design.'
  },
  {
    type: 'image',
    value: carbon,
    sm: 6,
    smOffset: 3,

  },
  {
    type: 'text',
    value: 'Sporting a half num row, I didn\'t see the V4N4G0N as a commercially, on brand, viable product. It made sense to me, but trying to explain to potential customers why some of the numbers were missing seemed needlessly challenging. Now that I didn\'t have anyone to answer to, I went for it. I had no idea if anyone else would like it. I didn\'t even care. I needed it.'
  },
  {
    type: 'quote',
    value: "I needed it",
    author: 'Trashman',
    lg: 8,
    lgOffset: 2
  },
  {
    type: 'text',
    value: 'Three runs later, it is a cult classic. With only about sixty-five of the R1/R2 cases and seventy-five of the R3 cases, it\'s a pretty rare keyboard and highly saught after. I recently moved. Prior to the move I sold a bunch of keyboards. I did something I thought I\'d never do. I sold my OG R1 V4N4G0N. $700 wasn\'t enough and I instantly regretted it. The only consollation was that I designed it and could potentially make more in the future'
  },
  {
    type: 'sub-header',
    value: 'The Same, but Different'
  },
  {
    type: 'text',
    value: 'After R3, which was based on the hull low profile case design, I really wanted to go back to the OG V4N4G0N\'s roots. I decided to leave the exterior mostly unchanged. I only wanted to cleanup some inconsistencies and make improvements to the existing design. Those improvements include reducing the number of case screw holes from 12 down to just 4. Alignment pins have been added to make case alignment super simple. The plate fitment is better and now uses tadpoles instead of bumpons. I didn\'t like the roated switches near the usb port so opted for a daughter-board to resolve that.'
  },
  {
    type: 'image',
    value: sand,
    sm: 6,
    smOffset: 3,

  },
  {
    type: 'text',
    value: 'The PCB on the other hand is completely new from the ground up. My first design utilizing a micro-controller that\'s not the ATMEGA32U4. I settled on the STM32 platform. My friend xNinjas sent me some Geon Raptors and I loved how they felt. I didn\'t even realize they didn\'t have switch pins. I knew I needed to find a way to utilize magnetic switches. Since there are no contacts on hall effect switches they are hotswappable by nature. This got me thinking, could a PCB support both MX and HE switches and be the ultimate hotswap board? The answer is yes. To my knowledge, this is the first hybrid MX/HE PCB. There is a tradeoff to this design, though. You lose all the analog features of the hall effect switches. No rapid trigger. No adjustable actuation.'
  },
  {
    type: 'text',
    value: 'For me the tradeoff was worth it. It would be a great way to try out hall effect and get a V4N4G0N back in my hands. Now that I\'ve had the prototype in front of me for a few weeks, I am more determined than ever to design a follow-up drop-in hall effect only PCB. Adjustable acutation, rapid trigger and anything else I can think up will be coming soon after the group buy for R4. I\'ve got the design worked out from a high level. I just need to test and validate each component. I am very excited to start work on the new PCB as it will include both circuit design as well as custom firmware.'
  },
  {
    type: 'text',
    value: 'For more pictures and a summary of the features/parts, checkout the store page at the following link.'
  },
  {
    type: 'link',
    value: '/page/v4n4g0n-r4',
    description: 'R4 Store Page'
  },
]

export default VanagonAgainPage;
