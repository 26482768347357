import { useParams } from "react-router-dom"
import data from './data'
import { ItemType } from './types'
import Image from 'react-bootstrap/Image'
import './style.css'
import Updates from '../../Components/Updates'
import { useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function InStock() {
  let params = useParams()

  useEffect(() => window.scrollTo(0, 0), [])

  const renderItem = (item: ItemType, index: number) => {
    switch (item.type) {
      case 'header':
        return <h3 key={index} className="header-element">{item.value}</h3>
      case 'sub-header':
        return <h4 key={index} className="sub-header-element">{item.value}</h4>
      case 'image':
        return <a key={index} rel="noopener" href={item.value}><Image className="img-element" src={item.value} fluid /></a>
      case 'html':
        return <div key={index} className="html-element" dangerouslySetInnerHTML={{ __html: item.value }} />
      case 'text':
        return <p key={index} className="text-element">{item.value}</p>
      case 'list':
        return <ul key={index} className="list-element">{item.values.map((listItem, index) => <li key={index}>{listItem}</li>)}</ul>
      case 'updates':
        return <Updates key={index} updates={item.updates} />
      case 'link':
        return <div key={index} className="link-element"><a href={item.value}>{item.description}</a></div>
      case 'quote':
        return <div key={index} className="quote-element"><p>{item.value}</p><span>{item.author}</span></div>
      default:
        return <span key={index}>error rendering section</span>
    }
  }

  return (
    <Row className="DynamicPage">
      {data[params?.pageId || 0].map((item: ItemType, index: number) => (
        <Col sm={{span: item.sm || 12, offset: item.smOffset}}
          md={{span: item.md, offset: item.mdOffset}}
          lg={{span: item.lg, offset: item.lgOffset}}
          xl={{span: item.xl, offset: item.xlOffset}}
          xxl={{span: item.xxl, offset: item.xxlOffset}}
          key={index}>
          {renderItem(item, index)}
        </Col>
      ))}
    </Row>
  );
}

export default InStock
